/** @jsx jsx */
import { jsx } from "theme-ui";

export const VisuallyHiddenCss = {
  clip: `rect(0 0 0 0)`,
  clipPath: `inset(50%)`,
  height: `1px`,
  overflow: `hidden`,
  position: `absolute`,
  whiteSpace: `nowrap`,
  width: `1px`,
};

export const VisuallyHidden = ({ children, ...rest }) => {
  return (
    <span css={VisuallyHiddenCss} {...rest}>
      {children}
    </span>
  );
};
