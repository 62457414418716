/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { keyframes } from "@emotion/core";
import { graphql, Link } from "gatsby";
import { Layout, Seo, Card } from "../components/";
import { VisuallyHidden } from "../utils";
import { GatsbyImage } from "gatsby-plugin-image";
import { Outlink, InternalLink } from "../styles/shared";

import portraitSrc from "../images/portrait.jpg";

const WrapperCss = {
  alignItems: `center`,
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `center`,
  maxWidth: `1200px`,
  marginBottom: `2rem`,
  // border: `8px solid`,
  // borderColor: "secondary",

  "@media (max-width: 800px)": {
    flexDirection: `column`,
    height: `unset`,
    margin: `0 auto 2rem`,
  },
};

const bounce = keyframes({
  "from, 20%, 53%, 80%, to": { transform: `translate3d(0, 0, 0)` },
  "40%, 43%": {
    transform: `translate3d(0, -30px, 0)`,
  },

  "70%": {
    transform: `translate3d(0, -15px, 0)`,
  },

  "90%": {
    transform: `translate3d(0,-4px,0)`,
  },
});

const ImageSectionCss = {
  flex: 1,
  padding: `0 2rem 0 2rem`,
  position: `relative`,

  "@media (max-width: 800px)": {
    padding: `2rem 1rem`,
    width: `100%`,
  },
};

const InfoSectionCss = {
  boxSizing: `border-box`,
  flex: 2,
  padding: `0 2rem 0 2rem`,

  "@media (max-width: 800px)": {
    padding: `2rem`,
    width: `100%`,
  },
};

const HeadingCss = {
  animation: `${bounce} 1s ease`,
  animationDelay: `500ms`,

  "@media (max-width: 400px)": {
    fontSize: `2.8rem`,
  },
};

const PortraitCss = {
  display: `block`,
  margin: `0 auto`,
  width: `100%`,

  "@media (max-width: 800px)": {
    width: `60%`,
  },
};

const LatestPostSectionCss = {
  marginBottom: `4rem`,
  "@media (max-width: 800px)": {
    padding: `2rem`,
  },
};

const ContentSectionCss = {
  "@media (max-width: 800px)": {
    padding: `2rem`,
  },
};

const IndexPage = ({ data }) => {
  const { portrait, featuredPost } = data;
  const post = featuredPost.edges[0].node;
  return (
    <Layout>
      <Seo title="Home" keywords={[`gatsby`, `react`, `accessibility`]} />
      <div sx={WrapperCss}>
        <section css={ImageSectionCss}>
          <GatsbyImage
            image={portrait.childImageSharp.gatsbyImageData}
            alt="Me (Amberley) smiling, wearing sunglasses"
            src={portraitSrc}
            css={PortraitCss}
          />
        </section>
        <section css={InfoSectionCss}>
          <Styled.p>Hi, there.</Styled.p>
          <Styled.h1 css={HeadingCss}>
            <Outlink href="https://twitter.com/amber1ey">I'm Amberley.</Outlink>
          </Styled.h1>
          <Styled.p>
            Frontend engineer, <Outlink href="https://a11y.coffee/">accessibility advocate</Outlink>
            {" "}and unrepentant generalist.
          </Styled.p>
          <Styled.p>
            Currently @{" "}
            <Outlink href="https://www.netlify.com/">Netlify</Outlink>.
            ICYMI
            newsletter (currently dormant) on{" "}
            <Outlink href="https://buttondown.email/amberley">
              Buttondown
            </Outlink>
            .
          </Styled.p>
          <Styled.p>
            Dog mom (x2).{" "}
            <Outlink href="https://ynab.com/referral/?ref=F2p1QlLbSyjmBE1I&utm_source=customer_referral">
              YNAB enthusiast
            </Outlink>
            .{" "}
            <Outlink href="/blog/2020-09-16-how-do-new-emoji-happen/">
              Yarn nerd
            </Outlink>
            . Clear eyes, full heart. You can sit with me.
          </Styled.p>
        </section>
      </div>
      <div css={LatestPostSectionCss}>
        <Styled.h2>Latest post</Styled.h2>
        <Link to={post.fields.slug} sx={{ textDecoration: `none` }}>
          <VisuallyHidden>{post.frontmatter.title}</VisuallyHidden>
          <Card as={`article`}>
            <Styled.h3 sx={{ marginBottom: `.4rem` }}>
              {post.frontmatter.title}
            </Styled.h3>
            <small sx={{ color: "text" }}>{post.frontmatter.date}</small>
            <Styled.p
              dangerouslySetInnerHTML={{
                __html: post.excerpt,
              }}
            />
          </Card>
        </Link>
        <div sx={{ textAlign: `right` }}>
          <InternalLink to="/blog" sx={{ fontSize: "3" }}>
            View more posts →
          </InternalLink>
        </div>
      </div>
      <div css={ContentSectionCss}>
        <Styled.h2>Writing Elsewhere</Styled.h2>
        <Styled.ul>
          <Styled.li>
            <Outlink href="https://css-tricks.com/a-browser-based-open-source-tool-for-alternative-communication/">
              A Browser-Based, Open Source Tool for Alternative Communication
            </Outlink>{" "}
            (CSS Tricks)
          </Styled.li>
          <Styled.li>
            <Outlink href="https://www.gatsbyjs.com/blog/2018-10-25-using-gatsby-without-graphql">
              Using Gatsby Without GraphQL
            </Outlink>{" "}
            (GatsbyJS)
          </Styled.li>
          <Styled.li>
            <Outlink href="https://medium.com/@amberleyjohanna/seriously-though-what-is-a-progressive-web-app-56130600a093">
              Seriously, though. What is a progressive web app?
            </Outlink>{" "}
            (Medium)
          </Styled.li>
          <Styled.li>
            <Outlink href="https://thearc.org/evolving-relationship-sister-angelman-syndrome/">
              My Evolving Relationship With my Sister with Angelman Syndrome
            </Outlink>{" "}
            (The Arc)
          </Styled.li>
          <Styled.li>
            <Outlink href="https://thearc.org/planting-trees-or-how-i-learned-how-to-plan-for-the-future/">
              Planting Trees (Or How I Learned How to Plan for the Future)
            </Outlink>{" "}
            (The Arc)
          </Styled.li>
        </Styled.ul>
      </div>
      <div css={ContentSectionCss}>
        <Styled.h2>Speaking</Styled.h2>
        <Styled.ul>
          <Styled.li>
            "
            <Outlink href="https://youtu.be/moGLzw6Wu00">
              Making Modern Web Performance Accessible.
            </Outlink>
            " (FITC Spotlight, 3.23.2019).
          </Styled.li>
          <Styled.li>
            "
            <Outlink href="https://youtu.be/WVoh9uNOUMk">
              What's Your JAMstack?
            </Outlink>
            ". (JAMstack Conf NYC, 4.9.2019).
          </Styled.li>
          <Styled.li>
            "
            <Outlink href="https://www.youtube.com/watch?v=0a5kmU0Dr80">
              A Deep Dive Into Gatsby Image
            </Outlink>
            ". (Gatsby Days NYC, 5.6.2019).
          </Styled.li>
        </Styled.ul>
      </div>
      <div css={ContentSectionCss}>
        <Styled.h2>Interviews</Styled.h2>
        <Styled.ul>
          <Styled.li>
            <Outlink href="https://a11yrules.com/podcast/e088-interview-with-amberley-romo-part-1/">
              Interview with Amberley Romo.
            </Outlink>{" "}
            (A11y Rules Podcast)
          </Styled.li>
          <Styled.li>
            <Outlink href="https://frontside.com/podcast/112-language-formation-with-amanda-hickman-and-amberley-romo/">
              Language Formation with Amanda Hickman and Amberley Romo.
            </Outlink>{" "}
            (The Frontside Podcast)
          </Styled.li>
          <Styled.li>
            <Outlink href="https://techjr.dev/episodes/2019/getting-into-gatsby-with-amberley-romo/">
              Getting into Gatsby with Amberley Romo.
            </Outlink>{" "}
            (Tech Jr Podcast)
          </Styled.li>
        </Styled.ul>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    portrait: file(relativePath: { eq: "portrait.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    featuredPost: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { contentType: { eq: "post" } }
        frontmatter: { hide: { ne: true } }
      }
      limit: 1
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
          excerpt
        }
      }
    }
  }
`;
